<template>
  <div class="customer">
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
    </v-row>
    <activity-logs-table :url="'/activity-logs'" />
  </div>
</template>
<script>
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import ActivityLogsTable from '@/components/tables/ActivityLogsTable.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    ActivityLogsTable,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Activity Logs',
          disabled: true,
          to: { name: 'activity-logs' },
        },
      ],
    }
  },
}
</script>
