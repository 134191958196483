<template>
  <v-card>
    <v-container>
      <v-card-title
        v-if="userId"
        class="mb-0 pb-1"
      >
        Activity Logs
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col class="col-md-3">
              <refresh-button
                :loading="isRefreshing"
                @click="refreshActivityLogs"
              />
            </v-col>
            <v-col class="col-md-6">
              <table-filter
                :table-name="'activity logs'"
                :default-date-picker-date="defaultDatePickerDate"
                @filter="applyFilters"
              />
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>
      </v-card-title>
      <v-timeline
        align-top
        dense
      >
        <v-timeline-item
          v-for="(log, index) in activityLogs"
          :key="index"
          color="blue-grey"
          small
        >
          <template v-slot:icon>
            <v-icon small>
              {{ determineIcon(log.description) }}
            </v-icon>
          </template>

          <span
            class="body-2 "
            v-html="log.description"
          ></span>

          <div class="caption text--secondary">
            {{ log.datetime }}
          </div>
        </v-timeline-item>
      </v-timeline>
      <div class="text-center">
        <infinite-loading
          ref="infiniteLoading"
          @infinite="infiniteHandler"
        />
      </div>
    </v-container>
  </v-card>
</template>

<script>
import axios from 'axios'
import {
  mdiLogin, mdiLogout, mdiPencil, mdiPlus, mdiDelete, mdiSend, mdiCashPlus,
} from '@mdi/js'
import InfiniteLoading from 'vue-infinite-loading'
import TableFilter from '@/components/partials/table-filter/index.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'

export default {
  components: {
    TableFilter,
    RefreshButton,
    InfiniteLoading,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      isRefreshing: false,
      activityLogs: [],
      currentPage: 1,
      isFirstLoad: true,
      isRefreshTriggered: false,
      defaultDatePickerDate: 'Last 30 days',
      filters: {
        dates: {
          fromDate: '',
          toDate: '',
        },
      },
    }
  },
  methods: {
    async fetchActivityLogs(isInfiniteLoading = false, $state = null) {
      this.isLoading = !isInfiniteLoading
      this.isRefreshing = isInfiniteLoading

      // Construct params object
      const params = {
        fromDate: this.filters.dates.fromDate,
        toDate: this.filters.dates.toDate,
        sortBy: 'created_at',
        sortDirection: 'desc',
        page: this.currentPage,
        userId: this.userId,
      }

      try {
        const response = await axios.get(this.url, { params })

        if (isInfiniteLoading) {
          this.activityLogs.push(...response.data.data) // Assuming records are inside `data` key
        } else {
          this.activityLogs = response.data.data // Reset if not infinite loading
        }

        // Check if there are more pages to load
        if (response.data.current_page >= response.data.last_page) {
          if ($state) $state.complete()
        } else {
          if ($state) $state.loaded()
          this.currentPage += 1 // Increment for next page
        }
      } catch (error) {
        console.error('fetchActivityLogs error:', error)
        this.$toast.error(error.response.data.message)
        if ($state) $state.error()
      } finally {
        this.isLoading = false
        this.isRefreshing = false
      }
    },
    infiniteHandler($state) {
      if (this.isFirstLoad) {
        this.isFirstLoad = false
        $state.loaded()

        return
      }

      // Skip if refresh is triggered
      if (this.isRefreshTriggered) {
        this.isRefreshTriggered = false // Reset the flag
        $state.loaded()

        return
      }
      this.fetchActivityLogs(true, $state)
    },
    refreshActivityLogs() {
      this.isRefreshTriggered = true
      this.currentPage = 1
      this.activityLogs = []

      this.$nextTick(() => {
        if (this.$refs.infiniteLoading) {
          this.$refs.infiniteLoading.stateChanger.reset()
        }
      })
    },
    determineIcon(description) {
      if (description.includes('logged in')) return mdiLogin
      if (description.includes('logged out')) return mdiLogout
      if (description.includes('updated')) return mdiPencil
      if (description.includes('created')) return mdiPlus
      if (description.includes('deleted')) return mdiDelete
      if (description.includes('sent')) return mdiSend
      if (description.includes('paid')) return mdiCashPlus
      if (description.includes('added')) return mdiCashPlus

      return mdiPencil // Default icon
    },
    applyFilters(filters) {
      this.filters = filters
      this.refreshActivityLogs()
    },
  },
}
</script>
